<!--
 * @Author: zhulin
 * @Date: 2020-07-23 15:54:42
 * @LastEditors: zhulin
 * @LastEditTime: 2021-09-08 17:19:32
 * @Description: 选择审批人
 * @FilePath: \user\src\components\businessDetail\components\columnRendering\flow\components\selectPerson.vue
-->
<template>
  <div>
    <en-dialog :title="name" :visible="dialogVisible" width="1000px" append-to-body :close-on-click-modal="false" @close="closeDialog">
      <en-transfer-data :config="transferSet" ref="transfer" v-model="personList" >
      </en-transfer-data>
      <span slot="footer" class="dialog-footer">
        <en-button type="primary" @click="submitSelect">确 定</en-button>
      </span>
    </en-dialog>
    <en-dialog title="提示" :visible.sync="confirmVisible" width="30%">
      <span>"{{ confirmNode }}"已参与审批，确定继续添加？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPerson">确 定</el-button>
      </span>
    </en-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import EnTransferData from "@/components/en-transfer-data";
import { selectService } from "@/api/select";

export default {
  name: "SelectPerson",
  components: { EnTransferData },
  props: {
    personData: {
      type: Array,
      default() {
        return [];
      }
    },
    name: {
      type: String,
      default: ""
    },
    multi: {
      type: Boolean,
      default: true
    },
    dialogVisible: Boolean
  },
  data() {
    return {
      personList: [],
      defaultProps: {
        label: "label",
        children: "children",
        value: "label"
      },
      confirmVisible: false,
      confirmNode: "",
      transferSet: {
        isMulti: this.multi, // 是否多选
        showTitle: false,
        selfDefine: true,
        hideRight: false,
        choiceRule: false,
        exclude: false, // 是否需要显示排除按钮
        dependData: {
          requestService: selectService, // 引入服务名称
          requestFn: "queryPersonTree", // 服务中方法
          requestParams: { pageNo: 1, pageSize: 9999, name: "" } // 请求参数

        }
      }
    };
  },
  mounted() {
    this.personList = cloneDeep(this.personData);
  },
  methods: {
    /**
     * @description: 关闭弹窗回调
     * @param {type}
     * @return:
     */
    closeDialog() {
      this.$emit("update:dialogVisible", false);
    },
    /**
     * @description: 添加人员
     */
    submitSelect() {
      if (this.name === "选择审批人") {
        const arr2 = this.personData.find((item) => item.userId === this.personList[0].id);
        if (arr2) {
          this.confirmNode = arr2.userName || "";
          this.confirmVisible = true;
        } else {
          this.addPerson();
        }
      } else {
        this.addPerson();
      }
    },
    /**
     * @description: 提交
     */
    addPerson() {
      this.confirmVisible = false;
      console.log("this.personList", this.personList);
      this.$emit("refreshList", this.personList, true);
      this.closeDialog();
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .en-transfer .en-transfer-panel-body.with-search{
  height: calc(100% - 51px);
}
/deep/ .el-input__validateIcon{
  display: none;
}
/deep/ .en-tree-node .el-checkbox{
 margin-right:8px;
}
/deep/ .en-transfer .en-transfer-panel-body .en-transfer-panel-item .el-checkbox__label{
  padding-left:12px
}
/deep/ .en-transfer .en-transfer-panel-body .el-tree-node__label{
  padding-left:0;
}
</style>
