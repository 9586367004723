var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "en-dialog",
        {
          attrs: {
            title: _vm.name,
            visible: _vm.dialogVisible,
            width: "1000px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: { close: _vm.closeDialog },
        },
        [
          _c("en-transfer-data", {
            ref: "transfer",
            attrs: { config: _vm.transferSet },
            model: {
              value: _vm.personList,
              callback: function ($$v) {
                _vm.personList = $$v
              },
              expression: "personList",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "en-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitSelect } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "en-dialog",
        {
          attrs: { title: "提示", visible: _vm.confirmVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.confirmVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              '"' + _vm._s(_vm.confirmNode) + '"已参与审批，确定继续添加？'
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.confirmVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addPerson } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }